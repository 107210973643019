<template>
    <div id="tab2" class="tab-content">
        <Calculator/>
        <section class="blockElement space pt-0 bg-white forexTool-page">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-12 col-lg-10">
                        <div class="bg-white boxedShadow">
                            <div class="topRadius">
                                <h1 class="f-30 semibold line-1">{{$t('calculator.text6')}}</h1>
                                <p>{{$t('calculator.text46')}}</p>
                                <Form class="allCalculator mt-md-5" @submit="marginSubmit">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text25')}}</label>
                                               <!--  <input type="text" class="form-control" v-model="searchCurr" @input="Searching($event)" placeholder="Search for CFD, stocks, commodities..." v-if="form.currencyId == ''" /> -->
                                                 <Field
                                                    type="text"
                                                    class="form-control"
                                                    name="currency"
                                                    autofill="off"
                                                    v-model="searchCurr"
                                                    rules="required"
                                                    placeholder="Search for CFD, stocks, commodities..." 
                                                    @input="Searching($event)" 
                                                    v-if="form.currencyId == ''"
                                                />
                                               <Field
                                                    type="text"
                                                    class="form-control"
                                                    name="currency_search"
                                                    autofill="off"
                                                    v-model="form.currencyId"
                                                    rules="required"
                                                    placeholder="" 
                                                    v-else
                                                />
                                                <div class="dropdown_menu_animated p-0 globalSearch mh-340 w-100 show" v-if="(searchCurr) && Object.keys(store.assetSearchList).length">
                                                    <ul class="globaListSearch">
                                                        <li v-for="item,index in Object.values(store.assetSearchList)" :key="index">
                                                            <a href="javascript:void(0)" class="d-flex align-items-center justify-content-between" @click="clearList(item)">
                                                                <span class="d-flex align-items-center">
                                                                    <span class="me-3">
                                                                        <v-lazy-image width="24" height="24" class="euCurrency" :src="static_vars.marketImageSURL+item.symbol.toUpperCase().replace(/\//g, '')+'.svg'" :alt="item.symbol.toString()" :title="item.symbol.toString()" />
                                                                    </span>
                                                                    <span class="me-2 f-15 menutext minWidthName medium">{{item.symbol}}</span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <ErrorMessage class="text-danger" name="currency" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <div class="d-flex align-items-center">
                                                    <label class="me-3" v-if="!isAmount">{{$t('calculator.text26')}}</label>
                                                    <label class="me-3" v-if="isAmount">{{$t('calculator.text47')}}</label>
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="isAmount"  />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <Field
                                                type="number"
                                                class="form-control"
                                                name="lots"
                                                autofill="off"
                                                v-model="form.lots"
                                                rules="required"
                                                v-if="!isAmount"
                                                @change="form.amount = ''"
                                                />
                                                <ErrorMessage class="text-danger" name="lots" />
                                                <Field
                                                type="number"
                                                class="form-control"
                                                name="amount"
                                                autofill="off"
                                                v-model="form.amount"
                                                rules="required"
                                                v-if="isAmount"
                                                @change="form.lots = ''"
                                                />
                                                <ErrorMessage class="text-danger" name="amount" />
                                            </div>
                                        </div>
                                        <!-- <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Choose Currency</label>
                                                <select class="form-select" v-model="form.currencyAccountId" >
                                                    <option value="EUR">EUR</option>
                                                    <option value="GBP">GBP</option>
                                                    <option value="JPY">JPY</option>
                                                    <option value="USD">USD</option>
                                                    <option value="CAD">CAD</option>
                                                    <option value="CHF">CHF</option>
                                                    <option value="AUD">AUD</option>
                                                </select>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>Pips</label>
                                                <Field
                                                type="number"
                                                class="form-control"
                                                name="pips"
                                                autofill="off"
                                                v-model="form.pips"
                                                rules="required"
                                                />
                                                <ErrorMessage class="text-danger" name="pips" />
                                            </div>
                                        </div> -->
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text48')}}</label>
                                                <Field
                                                  class="form-select"
                                                  as="select"
                                                  name="leverage"
                                                  rules="required"
                                                  placeholder=""
                                                  v-model="form.leverage"
                                                >
                                                <option v-for="item,key in leverageJSon" :key="key" :value="item">{{item}}</option>
                                                </Field>
                                                <ErrorMessage name="leverage" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text28')}}</label>
                                                <Field
                                                  class="form-select"
                                                  as="select"
                                                  name="base currency"
                                                  rules="required"
                                                  placeholder=""
                                                  v-model="form.baseCurrency"
                                                  v-if="store.calulatorBaseCurrencies.length"
                                                >
                                                <option value="">{{$t('calculator.text29')}}</option>
                                                <option v-for="item in store.calulatorBaseCurrencies" :key="item" :value="item">{{item}}</option>
                                                </Field>
                                                <ErrorMessage name="base currency" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>{{$t('calculator.text30')}}</label>
                                                <Field
                                                  class="form-select"
                                                  as="select"
                                                  name="mini lots"
                                                  rules="required"
                                                  placeholder=""
                                                  v-model="form.stdLots"
                                                >
                                                <option v-for="item,key in miniLotsJson" :key="item" :value="key">{{key}}</option>
                                                </Field>
                                                <ErrorMessage name="mini lots" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-12 text-center mt-3">
                                            <button type="submit" class="button fillSpiner fillBtn zulu_btn px-md-5">
                                                <Spinner class="buttonSpiner" v-if="loadingCalculator"></Spinner>{{$t('calculator.text31')}}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div class="getResult bottomRadius">
                                <ul class="resultList d-flex align-items-center flex-wrap mb-0">
                                    <li>
                                        <p class="f-30 mb-2">{{response.rate || '0.00'}}</p>
                                        <p class="mb-0 f-13">{{$t('calculator.text32')}}</p>
                                    </li>
                                    <li>
                                        <p class="f-30 mb-2">{{response.value || 0}}</p>
                                        <p class="mb-0 f-13">{{$t('calculator.text33')}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space faqs automated6">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4">{{$t('calculator.text12')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample1">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{$t('calculator.text49')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample1">
                                    <div class="accordion-body">{{$t('calculator.text50')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    {{$t('calculator.text51')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample1">
                                    <div class="accordion-body">{{$t('calculator.text52')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        {{$t('calculator.text53')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample1">
                                    <div class="accordion-body">{{$t('calculator.text54')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                       {{$t('calculator.text55')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample1">
                                    <div class="accordion-body">{{$t('calculator.text56')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    {{$t('calculator.text57')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample1">
                                    <div class="accordion-body">{{$t('calculator.text58')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingSix">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                   {{$t('calculator.text59')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample1">
                                    <div class="accordion-body">{{$t('calculator.text60')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store.ts";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import Calculator from "@/views/markets/calculator/calculator"
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        mounted(){
            this.currencylist = this.static_vars.currencyList
        },
        data() {
            return {
                loadingCalculator: false,
               currencylist: {},
                response: {},
                searchCurr : '',
                form: {
                    currencyId: '',
                    lots: '1',
                    currencyAccountId: '',
                    pips: '1',
                    stdLots: 'STANDARD',
                    leverage : '',
                    baseCurrency : '',
                    amount : ''
                },
                isAmount : false,
                miniLotsJson : {
                    'STANDARD' : 'Standard',
                    'MINI' : 'Mini',
                    'MICRO' : 'Micro',
                },
                leverageJSon : {}
            }
        },
        components: {
            Form,
            Field,
            ErrorMessage,
            Calculator
        },
        methods:{
           Searching(e) {
                if (e.target.value) {
                    this.searchdata = e.target.value;
                    this.store.callassetSearchList({ searchText: this.searchdata }, false);
                } else {
                    this.searchdata = "";
                }
            },
            clearList(item){
                this.form.currencyId = item.symbol;
                this.searchCurr = ''
                this.store.$patch({'assetSearchList' : {}})
            },
            marginSubmit(){
                if(this.form.currencyId && this.form.leverage){
                    this.store.margincalculate(this.form, true,this).then(response => {
                        this.loading = false
                        this.response = response
                    })
                }
            }
        },
        computed: {
            isActive() {
                return false
            }
        },
        created(){
            this.store.getLeverageList({},true).then(response =>{
                if(response.length){
                    this.leverageJSon = response
                    this.form.leverage = response[0]
                }
            })
        }
    }
</script>